<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="field-label">
          Amount
        </p>
      </v-col>
      <v-col cols="12">
        <table class="table-product-skus">
          <thead>
            <tr>
              <th>
                <p class="header-label">
                  Size
                </p>
              </th>
              <th>
                <p class="header-label">
                  Color
                </p>
              </th>
              <th>
                <p class="header-label">
                  Quantity
                </p>
              </th>
              <th>
                <p class="header-label">
                  Ratio
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, skuIndex) in skuDetail"
              :key="`sku-detail-${skuIndex}`">
              <td class="text-center">
                {{ sku.size }}
              </td>
              <td class="text-center">
                {{ sku.color }}
              </td>
              <td class="text-center sku-input">
                <v-text-field
                  v-model.number="sku.quantity"
                  outlined
                  hide-details
                  dense
                  @keypress="$keypressNumber($event)" />
              </td>
              <td class="text-center sku-input">
                <v-text-field
                  :value="showNumberFormat(sku.ratio)"
                  outlined
                  hide-details
                  readonly
                  dense />
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    skuDetail: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    bindValue () {
      this.$emit('input', this.skuDetail)
    },
    showNumberFormat (number = 0) {
      return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style lang="scss" scoped>
.table-product-skus {
  width: 100%;
  .sku-input {
    width: 250px;
  }
}
.header-label {
  font-weight: bold;
}
</style>
