<template>
  <v-row class="manufac-box">
    <v-col cols="6">
      <p class="field-label">
        ผลิตที่
      </p>
      <div class="d-flex">
        <v-radio-group
          v-model="form.manufacCountry"
          :error-messages="manufacCountryError"
          row>
          <v-radio
            v-for="(country, index) in selectOptions.countries"
            :key="`countries-${index}`"
            :label="country.text"
            :value="country.value" />
        </v-radio-group>
        <v-btn
          class="clear-country-btn"
          color="secondary"
          icon
          x-small
          @click="clearCountry()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="6" />
    <v-col
      cols="6"
      class="pb-0">
      <v-text-field
        v-model.number="form.clothesCostPerMetr"
        type="number"
        label="ราคาผ้า (หยวน)"
        :error-messages="clothesCostPerMetrError"
        :disabled="!hasManufacCountry"
        outlined
        @keypress="$keypressNumber($event)" />
      <v-text-field
        v-model.number="form.clothesLength"
        type="number"
        label="จำนวนผ้า (เมตร)"
        :error-messages="clothesLengthError"
        :disabled="!hasManufacCountry"
        outlined
        @keypress="$keypressNumber($event)" />
    </v-col>
    <v-col
      cols="6"
      class="pb-0">
      <v-text-field
        v-model.number="form.sellPrice"
        type="number"
        label="ราคาขาย (บาท)"
        :error-messages="sellPriceError"
        :disabled="!hasManufacCountry"
        outlined
        @keypress="$keypressNumber($event)" />
      <v-text-field
        v-model.number="form.manufacCost"
        type="number"
        :label="`ค่าผลิต (${isThailand ? 'บาท' : 'หยวน'})`"
        :disabled="!hasManufacCountry"
        :error-messages="manufacCostError"
        outlined
        @keypress="$keypressNumber($event)" />
    </v-col>
    <v-col
      v-for="(material, index) in materials"
      :key="`material-feild-${index}`"
      class="pt-0 d-flex"
      cols="6">
      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="materials[index].name"
            readonly
            label="ชื่ออะไหล่"
            hide-details
            :disabled="!hasManufacCountry"
            outlined />
        </v-col>
        <v-col cols="5">
          <v-text-field
            :value="totalCostCal(materials[index].unitPrice, materials[index].quantity)"
            readonly
            type="number"
            label="ค่าอะไหล่ (หยวน)"
            :disabled="!hasManufacCountry"
            hide-details
            outlined
            @keypress="$keypressNumber($event)" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col
          cols="6">
          <div class="d-flex">
            <p class="cost-calculate-label">
              ราคาต้นทุน ({{ clothesLength }} x {{ clothesCostPerMetr }} x 5) +
              {{ isThailand ? manufacCost : `(${manufacCost} x 5)` }} + {{ `(${materialsPrice} x 5)` }} =
            </p>
            <p class="cost-calculate-result ml-1">
              {{ totalCostPrice | showFullPriceFormat() }} บาท
            </p>
          </div>
          <div class="d-flex">
            <p class="cost-calculate-label">
              ราคาไม่รวม VAT = ({{ (form.sellPrice || 0) | showFullPriceFormat() }} / {{ 1.07 }}) =
            </p>
            <p class="cost-calculate-result ml-1">
              {{ (priceWithoutVat || 0) | showFullPriceFormat() }}
            </p>
          </div>
          <div class="d-flex">
            <p class="cost-calculate-label">
              Margin = ({{ ( sellPrice / 1.07 ) | showFullPriceFormat() }} / {{ totalCostPrice | showFullPriceFormat() }}) =
            </p>
            <p class="cost-calculate-result ml-1">
              {{ margin | showFullPriceFormat() }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    totalCostPrice: {
      type: Number,
      default: 0
    },
    margin: {
      type: Number,
      default: 0
    },
    materials: {
      type: Array,
      default: () => [{
        images: [],
        note: '',
        quantity: 0,
        lastQty: 0,
        unitPrice: 0,
        name: ''
      }]
    },
    priceWithoutVat: {
      type: Number,
      default: 0
    },
    isThailand: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      replacement: [],
      selectOptions: {
        countries: [
          { text: 'ประเทศไทย', value: 'thailand' },
          { text: 'ประเทศจีน', value: 'china' }
        ]
      }
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },

    clothesLength () {
      return this.form.clothesLength || 0
    },
    clothesCostPerMetr () {
      return this.form.clothesCostPerMetr || 0
    },
    manufacCost () {
      return this.form.manufacCost || 0
    },
    sellPrice () {
      return this.form.sellPrice || 0
    },
    materialsPrice () {
      return this.materials.reduce((total, current) => total + (current.quantity * current.unitPrice), 0) || 0
    },
    clothesLengthError () {
      return this.isValid(this.form.clothesLength) ? '' : 'กรุณากรอกจำนวนผ้า'
    },
    clothesCostPerMetrError () {
      return this.isValid(this.form.clothesCostPerMetr) ? '' : 'กรุณากรอกราคาผ้า'
    },
    manufacCostError () {
      return this.isValid(this.form.manufacCost) ? '' : 'กรุณากรอกค่าผลิต'
    },
    sellPriceError () {
      return this.isValid(this.form.sellPrice) ? '' : 'กรุณากรอกราคาขาย'
    },
    manufacCountryError () {
      const hasForm = !!this.form.sellPrice || !!this.form.manufacCost || !!this.form.clothesCostPerMetr || !!this.form.clothesLength
      return !this.form.manufacCountry && hasForm ? 'กรุณาเลือกประเทศที่ผลิต' : ''
    },
    hasManufacCountry () {
      return !!this.form.manufacCountry
    }
  },
  watch: {
    replacement: {
      handler (value) {
        this.form.replacement = value
        this.$emit('input', this.form)
      },
      deep: true
    }
  },
  methods: {
    totalCostCal (unitPrice, quantity) {
      return unitPrice * quantity || null
    },
    isEmpty (value, alert) {
      return !value ? alert : ''
    },
    clearCountry () {
      this.form.manufacCountry = null
    },
    isValid (data) {
      return !!this.form.manufacCountry && (!!data || data === 0)
    },
    addMoreReplacementField () {
      this.form.replacement.push({ name: null, value: null })
    },
    removeReplacementField (index) {
      this.form.replacement.splice(index, 1)
    },
    replacementCompute (replacement) {
      return replacement?.reduce((total, current) => total + current, 0) || 0
    }
  }
}
</script>

<style>
.field-label {
  font-weight: bold;
  margin: 0px;
}
.cost-calculate-result {
  font-weight: bold;
}
.manufac-box {
  width: 100%;
}

.clear-country-btn {
  margin: 22px 8px 0;
}
</style>
