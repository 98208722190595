<template>
  <section
    id="createSketch"
    class="d-flex justify-center">
    <div
      v-if="pageReady"
      class="create-order-form-content-box">
      <v-row>
        <v-col cols="12">
          <h1 class="title">
            Create Order Form
          </h1>
        </v-col>
      </v-row>
      <v-row class="create-order-form">
        <v-col cols="6">
          <gw-drop-file
            v-model="form.fitting.images"
            :multiple="true"
            label="Fitting"
            need-compress
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            label-bold />
        </v-col>
        <v-col cols="6">
          <p class="field-label mb-4">
            Note
          </p>
          <v-textarea
            v-model="form.fitting.note"
            outlined />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <material-stage-old v-model="form.materials" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.productId"
            outlined
            label="Product ID"
            dense
            :disabled="!!sketch.sketchNo" />
          <v-text-field
            v-model="form.productFactoryId"
            outlined
            label="Product Factory ID"
            dense />
          <v-select
            v-model="form.factory"
            :items="selectOptions.factories"
            item-text="name"
            return-object
            outlined
            label="Factory"
            dense />
          <tree-select
            v-model="form.subCategory"
            :items="selectOptions.subCategories"
            label="Sub-Categories"
            item-text="name"
            item-value="id"
            sub-property="children"
            return-object
            dense />
          <date-with-menu
            v-model="form.orderDate"
            label="Order Date"
            :default-date="$dayjs().format('YYYY-MM-DD')"
            dense />
          <date-with-menu
            v-model="form.targetDate"
            label="Target Date"
            :default-date="$dayjs().add(1, 'month').format('YYYY-MM-DD')"
            dense />
          <p class="field-label mb-4 mt-8">
            Product Sku
          </p>
          <v-combobox
            v-model="sizeDetail"
            label="Size"
            :items="sizes"
            multiple
            outlined
            deletable-chips
            small-chips
            dense
            :rules="arrayRules" />
          <v-combobox
            v-model="colorsSkus"
            :items="colors"
            label="Color"
            multiple
            outlined
            deletable-chips
            small-chips
            dense
            :rules="arrayRules" />
        </v-col>
        <v-col cols="12">
          <manufac-calculate-old
            v-model="manufac"
            :total-cost-price="totalCostPrice"
            :materials="form.materials"
            :margin="skuRatio"
            :price-without-vat="priceWithoutVat"
            :is-thailand="isThailand" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <sku-detail-old v-model="form.productSkus" />
        </v-col>
        <v-col cols="12">
          <sizing-detail
            v-model="form.sizeDetail"
            :size-unit.sync="form.sizeUnit" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center">
          <v-btn
            color="secondary"
            @click="updateOrder()">
            SAVE
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FactoryProvider from '@/resources/FactoryProvider'
import PrototypeOrderProviderOld from '@/resources/PrototypeOrderProviderOld'
// import PrototypeProviderOld from '@/resources/PrototypeProviderOld'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import PrototypeSubCategoryProvider from '@/resources/PrototypeSubCategoryProvider'
import GetImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import MaterialStageOld from '../components/MaterialStageOld.vue'
import DateWithMenu from '../components/DateWithMenu.vue'
import ManufacCalculateOld from '../components/ManufactureCalculateOld.vue'
import SkuDetailOld from '../components/SkuDetailOld.vue'
import SizingDetail from '../components/SizingDetail.vue'
import TreeSelect from '../components/TreeSelect.vue'

const FactoryService = new FactoryProvider()
const PrototypeOrderService = new PrototypeOrderProviderOld()
// const PrototypeService = new PrototypeProviderOld()
const ProductAttributeService = new ProductAttributeProvider()
const PrototypeSubCategoryService = new PrototypeSubCategoryProvider()

export default {
  components: {
    MaterialStageOld,
    DateWithMenu,
    ManufacCalculateOld,
    SkuDetailOld,
    SizingDetail,
    TreeSelect
  },
  data () {
    return {
      assistData: {
        productId: null,
        productFactoryId: null
      },
      pageReady: false,
      form: {
        fitting: {
          images: [],
          note: null
        },
        materials: [
          {
            images: [],
            note: '',
            quantity: 0,
            lastQty: 0,
            unitPrice: 0,
            name: ''
          }
        ],
        productId: null,
        productFactoryId: null,
        factory: {
          id: null,
          name: null
        },
        orderDate: this.$dayjs().format('YYYY-MM-DD'),
        targetDate: this.$dayjs().add(1, 'month').format('YYYY-MM-DD'),
        manufacCountry: null,
        clothesCostPerMetr: null,
        clothesLength: null,
        sellPrice: null,
        manufacCost: null,
        sizeDetail: [],
        sizeUnit: '',
        productSkus: [],
        subCategory: {
          id: null,
          name: '',
          parentId: null
        }
      },
      manufac: {},
      selectOptions: {
        factories: [],
        colors: [],
        sizes: [],
        subCategories: []
      },
      sketch: {},
      prototypeState: '',
      arrayRules: [
        (v) => Boolean(v?.length || false) || 'Field is require!'
      ]
    }
  },
  computed: {
    ...mapGetters({
      recentlyRoute: 'Route/recentlyRoute'
    }),
    factories () {
      return this.selectOptions.factories
    },
    colors () {
      return this.selectOptions.colors.map((s) => s.name)
    },
    sizes () {
      return this.selectOptions.sizes.map((s) => s.name)
    },
    priceWithoutVat () {
      return this.manufac?.sellPrice ? this.manufac.sellPrice / 1.07 : 0
    },
    skuRatio () {
      const ratio = Number(parseFloat(this.priceWithoutVat / this.totalCostPrice).toFixed(2)) || 0
      this.mapRatio(ratio)
      return ratio
    },
    isThailand () {
      return this.manufac.manufacCountry === 'thailand'
    },
    totalClothesCost () {
      return this.manufac.clothesCostPerMetr * this.manufac.clothesLength * 5
    },
    materialsPrice () {
      return this.form.materials.reduce((acc, cur) => acc + (cur.quantity * cur.unitPrice), 0) * 5 || 0
    },
    totalCostPrice () {
      let cost = 0
      if (this.isThailand) {
        cost = this.manufac.manufacCost + this.totalClothesCost + this.materialsPrice || 0
      } else {
        cost = (this.manufac.manufacCost * 5) + this.totalClothesCost + this.materialsPrice || 0
      }
      this.mapCost(cost)
      return cost
    },
    prototypeId () {
      return this.$route?.params?.id || ''
    },
    sizeDetail: {
      get () {
        return this.form.sizeDetail.map((item) => item.size.trim())
      },
      set (newVal) {
        const newArr = [...new Set(newVal)].filter((s) => this.sizes.includes(s))
        this.setSizeDetail(newArr)
        this.setProductSkus(this.colorsSkus, newArr)
      }
    },
    colorsSkus: {
      get () {
        return [...new Set(this.form.productSkus.map((item) => item.color.trim()))]
      },
      set (newVal) {
        const newArr = [...new Set(newVal)].filter((c) => this.colors.includes(c))
        this.setProductSkus(newArr, this.sizeDetail)
      }
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setDarkMode: 'Style/setDarkMode',
      setIsWebpSupported: 'Style/setIsWebpSupported',
      initStore: 'Store/initStore'
    }),
    mapRatio (ratio = this.skuRatio) {
      this.form.productSkus.forEach((sku, index) => {
        this.form.productSkus[index].ratio = ratio
      })
    },
    mapCost (cost = this.skuRattotalCostPriceio) {
      this.form.productSkus.forEach((sku, index) => {
        this.form.productSkus[index].cost = cost
      })
    },
    setProductSkus (colors, sizes) {
      const { productSkus } = this.form
      const skusLength = productSkus.length
      const result = []
      colors.forEach((color) => {
        const colorData = this.selectOptions.colors.find((c) => c.name === color)

        sizes.forEach((size) => {
          const sizeData = this.selectOptions.sizes.find((s) => s.name === size)
          const oldData = productSkus.find((sku) => sku.color.toUpperCase() === color && sku.size.toUpperCase() === size)
          const data = {
            colorId: colorData.id,
            color,
            sizeId: sizeData.id,
            size,
            quantity: oldData?.quantity || null,
            ratio: this.skuRatio || 0,
            cost: this.totalCostPrice || 0,
            productSellsuki: oldData?.productSellsuki || null
          }

          if (!(data?.productSellsuki?.productId)) {
            delete data.productSellsuki
          }

          result.push(data)
        })
      })
      this.form.productSkus.splice(0, skusLength, ...result)
    },
    setSizeDetail (newArr) {
      if (newArr.length >= this.form.sizeDetail.length) {
        newArr.forEach((size) => {
          const findIndex = this.form.sizeDetail.findIndex((item) => item.size === size)
          if (this.form.sizeDetail.length > 0 && findIndex !== -1) {
            this.form.sizeDetail.splice(findIndex, 1, {
              ...this.form.sizeDetail[findIndex]
            })
          } else if (this.form.sizeDetail.length > 0) {
            this.form.sizeDetail.push({
              options: this.form.sizeDetail[0].options.map((option) => ({
                key: option?.key || '',
                value: ''
              })),
              size
            })
          } else {
            this.form.sizeDetail.push({
              options: [{
                key: '',
                value: ''
              }],
              size
            })
          }
        })
      } else {
        this.form.sizeDetail.forEach((item, index) => {
          const findIndex = newArr.findIndex((size) => item.size === size)
          if (findIndex === -1) {
            this.form.sizeDetail.splice(index, 1)
          }
        })
      }
    },
    sizeDetailUpperCaseRules (value) {
      let data = true
      for (let i = 0; i < value.length; i++) {
        if (value[i] !== value[i].toUpperCase() || value[i].trim() === '' || value[i] !== value[i].trim()) {
          data = false
          break
        }
      }
      return data || 'Require to uppercase letter and no space.'
    },
    async updateOrder () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATE ORDER...'
        })

        if (!this.sketch.category) {
          this.setSnackbar({
            value: true,
            message: 'Please fill category / type in sketch before edit order.',
            type: 'error'
          })

          this.setLoading({ active: false })

          return
        }

        // const { data: existedProductId } = await PrototypeService.getPrototypeByProductId(this.form.productId)
        // if (existedProductId && this.assistData.productId !== this.form.productId) {
        //   const error = {
        //     code: 400,
        //     message: 'Duplicate Product Id.'
        //   }
        //   throw error
        // }

        // const { data: existedProductFactoryId } = await PrototypeService.getPrototypeByProductFactoryId(this.form.productFactoryId)

        // if (existedProductFactoryId && this.assistData.productFactoryId !== this.form.productFactoryId) {
        //   const error = {
        //     code: 400,
        //     message: 'Duplicate Product Factory Id.'
        //   }

        //   throw error
        // }

        const form = this.removeUnuseData({
          ...this.form,
          ...this.manufac,
          productSkus: this.form.productSkus.map((sku) => {
            const colorSku = this.colorsSkus.find((c) => c === sku.color)
            const colorData = this.selectOptions.colors.find((c) => c.name === sku.color)
            const sizeData = this.selectOptions.sizes.find((s) => s.name === sku.size)

            return {
              colorId: colorData.id,
              color: colorData?.name || colorSku,
              sizeId: sizeData.id,
              size: sizeData.name || sku.size,
              quantity: sku?.quantity || null,
              ratio: this.skuRatio || 0,
              cost: this.totalCostPrice || 0,
              percent: sku?.percent || null,
              productSellsuki: colorSku?.productSellsuki || null
            }
          })
        })

        await PrototypeOrderService.editOrder(this.prototypeId, form)

        this.setSnackbar({
          value: true,
          message: 'Prototype has been updated',
          type: 'success'
        })

        this.redirectRoute()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    redirectRoute () {
      switch (this.prototypeState) {
        case 'order':
          if (this.recentlyRoute?.name === 'OrderFormListOld') {
            this.$router.push(this.recentlyRoute)
          } else {
            this.$router.push({ name: 'OrderFormListOld' })
          }
          break
        case 'manufacturing':
          if (this.recentlyRoute?.name === 'ManufactureListOld') {
            this.$router.push(this.recentlyRoute)
          } else {
            this.$router.push({ name: 'ManufactureListOld' })
          }
          break
        default:
          this.$router.push({ name: 'OrderFormListOld' })
          break
      }
    },
    async getOrder () {
      try {
        const { data } = await PrototypeOrderService.getOneOrderById(this.prototypeId)
        this.form = {
          ...this.form,
          ...data.order
        }
        this.sketch = {
          ...data.sketch
        }
        this.prototypeState = data.state || 'order'

        this.assistData = {
          productId: this.form.productId,
          productFactoryId: this.form.productFactoryId
        }

        this.initManufac(data.order)
        this.initMaterials(data.order.materials)
        this.initDefaultObject()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    },
    async initSelectOptions () {
      try {
        const [factoryPromise, attributePromise, subCategoriesPromise] = await Promise.all([
          FactoryService.getAll(),
          ProductAttributeService.getAllProductAttribute(),
          PrototypeSubCategoryService.getAllMapped()
        ])

        this.selectOptions.factories = factoryPromise.data.results.sort((a, b) => a.id - b.id)
        this.selectOptions.colors = attributePromise.data.colors ? this.getColor(attributePromise.data.colors).sort((a, b) => a.id - b.id) : []
        this.selectOptions.sizes = attributePromise.data.size ? attributePromise.data.size.sort((a, b) => a.id - b.id) : []
        this.selectOptions.subCategories = subCategoriesPromise.data.map((d) => ({
          ...d,
          disabled: d.name === 'UNKNOWN'
        }))
      } catch (error) {
        console.error('initSelectOptions', error)
      }
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: GetImageOrGradientCss(color)
      }))
    },
    initDefaultObject () {
      this.form.fitting = this.form?.fitting || { images: [], note: null }
      this.form.materials = this.form?.materials?.length > 0 ? this.form.materials : [{
        images: [],
        note: '',
        quantity: 0,
        lastQty: 0,
        unitPrice: 0,
        name: ''
      }]
      this.form.factory = this.form?.factory || { name: null, id: null }
    },
    initMaterials (materials) {
      this.form.materials = materials.map((material) => ({
        ...material,
        lastQty: material.quantity,
        name: material.name || '',
        totalCost: material.totalCost || ''
      }))
    },
    initManufac ({ sellPrice, manufacCountry, manufacCost, clothesCostPerMetr, clothesLength, ratio, replacement }) {
      this.manufac = {
        sellPrice,
        manufacCountry,
        manufacCost,
        clothesCostPerMetr,
        clothesLength,
        replacement: replacement || [],
        ratio
      }
    },
    async initForm () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING ORDER...'
        })

        await this.initSelectOptions()
        await this.getOrder(this.prototypeId)

        this.pageReady = true
      } catch (error) {
        console.error(error)
      } finally {
        this.setLoading({ active: false })
      }
    },
    removeUnuseData (_obj) {
      const obj = _obj
      const keys = Object.keys(obj)

      keys.forEach((key) => {
        if (key !== 'orderDate' && key !== 'targetDate' && key !== 'productSellsuki' && key !== 'options') {
          if (obj[key] && typeof obj[key] === 'object') {
            obj[key] = this.removeUnuseData(obj[key])
          } else if ((!obj[key] || JSON.stringify(obj[key]) === '{}')) {
            delete obj[key]
          }
        }
      })

      return JSON.stringify(obj) !== '{}' ? obj : null
    }
  }
}
</script>

<style scoped>
.create-order-form-content-box {
  padding: 15px 15px;
  background-color: #fff;
  /* max-width: 1030px; */
  width: 100%;
}
.create-order-form {
  margin: 16px 0px;
}
.field-label {
  font-weight: bold;
  margin: 0px;
}
</style>
