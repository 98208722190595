<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        outlined
        readonly
        v-bind="attrs"
        dense
        hide-details
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="setMenu(false)">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    defaultDate: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    date: {
      get () {
        let date = new Date().toISOString().substr(0, 10)
        if (this.value) {
          date = this.$dayjs(this.value).format('YYYY-MM-DD')
        } else {
          date = this.defaultDate
          this.$emit('input', date)
        }
        return date
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  setMenu (data) {
    this.menu = data
  }
}
</script>

<style>

</style>
