import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PrototypeOrderProvider extends HttpRequest {
  constructor () {
    super(`${process.env.VUE_APP_MER_OLD_API}/prototypes`)
  }

  getManyOrder (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/order', query)
  }

  getPrototypes (query) {
    this.setHeader(getAuthToken())
    return this.get('/order', query)
  }

  editOrder (id, prototype) {
    this.setHeader(getAuthToken())
    return this.put(`/order/${id}`, prototype)
  }

  createOrder (ids) {
    this.setHeader(getAuthToken())
    return this.post('/order', ids)
  }

  getOneOrderById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/order/${id}`)
  }

  editManyOrders (payload) {
    this.setHeader(getAuthToken())
    return this.put('/order/many', payload)
  }

  deleteOrderById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/${id}`)
  }
}

export default PrototypeOrderProvider
